import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AdminLayout from './components/AdminLayout/AdminLayout';
import Login from './components/login/Login';
import SecondLogin from './components/SecondLogin/SecondLogin';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { FileProvider } from './context/FileContext'; // Import the provider
import Register from './components/register/Register';


function App() {
  return (
    <FileProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/second-login" element={<SecondLogin />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
    </FileProvider>

  );
}

export default App;
