import React from 'react';
import './QualityTestingOptionsMenu.css';

const QualityTestingOptionsMenu = () => {
  return (
    <div className="quality-testing-options-menu">
      <h2 className="sidebar-title">Data</h2>
      <div className="sidebar-content">
        <div className="sidebar-item">
          <span className="label">Radiation Source Type</span> 
          <span className="value">Gamma Rays</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Energy Levels</span> 
          <span className="value">1.5 MeV</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Initial Radiation Dose</span> 
          <span className="value">3 Gy</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Dose Rate</span> 
          <span className="value">0.3 Gy per hr</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Exposure Duration</span> 
          <span className="value">100 Hrs</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Ambient Temperature</span> 
          <span className="value">20-25°C</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Pressure</span> 
          <span className="value">101.3 kPa</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Humidity</span> 
          <span className="value">5%</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Material Thickness</span> 
          <span className="value">5 mm</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Radiation Attenuation</span> 
          <span className="value">85% reduc.</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Penetration Depth</span> 
          <span className="value">1.5 mm</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Tensile Strength Degradation</span> 
          <span className="value">7%</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Change in Elasticity</span> 
          <span className="value">5%</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Thermal Stability Range</span> 
          <span className="value">50 - 120°C</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Internal Dosimeter</span> 
          <span className="value">Sv 0.03</span>
        </div>
        <div className="sidebar-item">
          <span className="label">External Dosimeter</span> 
          <span className="value">Sv 3.50</span>
        </div>
        <div className="sidebar-item status-pass">
          <span className="label">Compliance with Safety Standards</span> 
          <span className="value">Pass</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Sensor Data, Real-time</span> 
          <span className="value">Stable</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Chemical Analysis Results</span> 
          <span className="value">No changes</span>
        </div>
        <div className="sidebar-item">
          <span className="label">Visual Inspection Findings</span> 
          <span className="value">No visible damage</span>
        </div>
      </div>
    </div>
  );
};

export default QualityTestingOptionsMenu;
