import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import axios from 'axios'; // Import axios for HTTP requests
import { Container, Row, Col, Card, Button, Image, Modal } from 'react-bootstrap';
import './Home.css'; // For any additional custom styles
import { API_BASE_URL } from '../../../config/config';
import VyomHelmetModal from './HomeModals/VyomHelmetModal/VyomHelmetModal';
import VyomChestPieceModal from './HomeModals/VyomChestPieceModal/VyomChestPieceModal';
import VyomGlovesModal from './HomeModals/VyomGlovesModal/VyomGlovesModal';
import VyomBootsModal from './HomeModals/VyomBootsModal/VyomBootsModal';

export default function Home() {

  const [showModal, setShowModal] = useState(null);
  const [user, setUser] = useState(null); // State to store user details
  const navigate = useNavigate(); // For navigation
  const handleOpenModal = (modalName) => {
    setShowModal(modalName);
  };

  const handleCloseModal = () => {
    setShowModal(null);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(`${API_BASE_URL}/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // If the response is successful, store user details in local storage
        setUser(response.data); // Store user details in state
        localStorage.setItem('user', JSON.stringify(response.data)); // Optional: Store user details in local storage
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          // Handle invalid or expired token
          console.error(error.response.data.msg);
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          console.error('An error occurred while fetching user details.');
        }
      }
    };

    fetchUserDetails();
  }, [navigate]);

  return (
    <Container fluid className="home-container">
  <Row>
    <Col xs={12} sm={6} md={5} lg={5}>
      {/* Astronaut Image and Circles */}
      <div className="image-container">
        <img
          src={require('../../../assets/admin/home/terra-studio-alpha 2.png')}
          alt="Astronaut"
          className="astronaut-img"
        />
        <div className="home-eclipse-circle"></div>
        {/* Circle positions */}
        <div className="home-circle helmet-circle" onClick={() => handleOpenModal('helmet')}></div>
        <div className="home-circle chest-circle" onClick={() => handleOpenModal('chest')}></div>
        <div className="home-circle gloves-circle" onClick={() => handleOpenModal('gloves')}></div>
        <div className="home-circle boots-circle" onClick={() => handleOpenModal('boots')}></div>
        {[...Array(8)].map((_, index) => (
          <div
            key={index}
            className={`home-circle additional-circle additional-circle-${index}`}
            onClick={() => handleOpenModal('boots')}
          ></div>
        ))}
      </div>

      {/* Modals */}
      {showModal === 'helmet' && (
        <VyomHelmetModal show={showModal === 'helmet'} handleClose={handleCloseModal} />
      )}
      {showModal === 'chest' && (
        <VyomChestPieceModal show={showModal === 'chest'} handleClose={handleCloseModal} />
      )}
      {showModal === 'gloves' && (
        <VyomGlovesModal show={showModal === 'gloves'} handleClose={handleCloseModal} />
      )}
      {showModal === 'boots' && (
        <VyomBootsModal show={showModal === 'boots'} handleClose={handleCloseModal} />
      )}
    </Col>
    <Col xs={12} sm={6} md={7}>
          {/* Your Tasks Card */}
          <Row className='mb-4'>
            <Col md={12}>
              <div className="tasks-section">
                <div className="section-header">
                  <h5>Your Tasks</h5>
                </div>
                <div className="home-divider"></div>
                <div className="tasks-cards mt-4">
                  {/* First Card (Highlighted) */}
                  <Card className="task-card highlighted-card bg-dark text-white">
                    <Card.Body>
                      <Card.Text>
                        <strong className="home-highlighted-text">Nadia</strong> assigned you to a new <strong className="home-highlighted-text">visibility</strong> test for the <strong className="home-highlighted-text">Vyom Helmet</strong> 25 minutes ago.
                      </Card.Text>
                      <Row className='mt-5'>
                        <Col md={10}>
                          <Card.Text className="task-due">Due: 08.9.2023</Card.Text>
                        </Col>
                        <Col md={2} className="mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#8a2be2"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="task-arrow"
                          >
                            <line x1="-10" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  {/* Second Card (Dimmed) */}
                  <Card className="task-card dimmed-card bg-dark text-white">
                    <Card.Body>
                      <Card.Text>
                        <strong className="highlighted-text">Nadia</strong> assigned you to a new <strong className="highlighted-text">visibility</strong> test for the <strong className="highlighted-text">Vyom Helmet</strong> 25 minutes ago.
                      </Card.Text>
                      {/* <div className="task-footer"> */}
                      <Row>
                        <Col md={10}>
                          <Card.Text className="task-completed">Completed: 05.08.2023</Card.Text>
                        </Col>
                        <Col md={2} className="mb-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#969696" 
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="checkmark-symbol"
                          >
                            <path d="M5 12 L10 17 L19 7" />
                          </svg>

                        </Col>
                      </Row>
                      <div className="task-arrow-container">
                        <div className="task-arrow">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                              <polyline points="12 5 19 12 12 19"></polyline>
                            </svg> */}
                        </div>
                      </div>
                      {/* </div> */}
                      <div className='home-square-box'>
                        <i className='fa fa-arrow-right home-rotated-icon'></i>
                      </div>

                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="updates-section">
                {/* Section Header */}
                <div className="section-header">
                  <h5>Updates</h5>
                  <hr />
                </div>
                <div className="home-divider"></div>
                {/* Updates Cards */}
                <div className="updates-cards mt-3">
                  {/* First Card (Highlighted) */}
                  <Card className="update-card highlighted-card bg-dark text-white">
                    <Card.Body>
                      <div className="update-header">
                        <Row>
                          <Col md={3}>
                            <Image
                              src={require('../../../assets/admin/table-images/image2.png')}
                              roundedCircle
                              className="user-image"
                            />
                          </Col>
                          <Col md={9}>
                            <div className="user-info">
                              <Row>
                                <Col md={6}>
                                  <strong>Alex L.</strong>
                                </Col>
                                <Col md={6}>
                                  <span className="timestamp">3:36pm</span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <Card.Text className="update-title">Great Job</Card.Text>
                      <div className="home-divider"></div>
                      <Card.Text className="update-message">
                        Hey everyone, I have been super pleased with everyone's progress. Keep it up. If you're in the office on Wednesday, there will be pizza for lunch.
                      </Card.Text>
                      <Card.Text className="update-footer">
                        Sent to... and 13 others
                      </Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Second Card (Dimmed) */}
                  <Card className="update-card dimmed-card bg-dark text-white">
                    <Card.Body>
                      <div className="update-header">
                        <Image
                          src={require('../../../assets/admin/table-images/image3.png')}
                          roundedCircle
                          className="user-image"
                        />
                        <div className="user-info">
                          <strong>Kaley M.</strong>
                          <span className="timestamp">1:30pm</span>
                        </div>
                      </div>
                      <Card.Text className="update-title">Meeting at 1:30</Card.Text>
                      <Card.Text className="update-message">
                        Just wanted to remind everyone of the progress meeting tomorrow.
                      </Card.Text>
                      {/* <div className="task-arrow-container">
                        <div className="task-arrow">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>
                        </div>
                      </div> */}
                      <div className='home-square-box'>
                        <i className='fa fa-arrow-right home-rotated-icon'></i>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>


          {/* Progress Card */}
          <Row>
            <Col md={12}>
              <div className="progress-section">
                {/* Section Header */}
                <div className="section-header">
                  <h5>Progress</h5>
                  <hr />
                </div>
                <div className="home-divider"></div>

                {/* Progress Cards */}
                <div className="progress-cards mt-3">
                  <Row>
                    {/* First Card (Highlighted) */}
                    <Col md={6}>
                      <Card className="progress-card highlighted-card bg-dark text-white">
                        <Card.Body>
                          <Card.Title>Gloves</Card.Title>
                          <div className="progress-details">
                            <ul>
                              <li>10 Completed</li>
                              <li>5 In Progress</li>
                              <li>11 Unassigned</li>
                            </ul>
                            <div className="home-progress-circle">
                              <span>46%</span>
                            </div>
                          </div>
                          <div className="progress-update">Yesterday</div>
                        </Card.Body>
                      </Card>
                    </Col>

                    {/* Second Card */}
                    <Col md={6}>
                      <Card className="progress-card dimmed-card bg-dark text-white">
                        <Card.Body>
                          <Card.Title>Boot</Card.Title>
                          <div className="progress-details">
                            <ul>
                              <li>10 Completed</li>
                              <li>5 In Progress</li>
                              <li>11 Unassigned</li>
                            </ul>
                            <div className="progress-circle">
                              <span>46%</span>
                            </div>
                          </div>
                          <div className="progress-update">Updated: 05.09.2023</div>
                        </Card.Body>
                      </Card>
                    </Col>

                    {/* Third Card */}
                    <Col md={6}>
                      <Card className="progress-card bg-dark text-white">
                        <Card.Body>
                          <Card.Title>Chest</Card.Title>
                          <div className="progress-details">
                            <ul>
                              <li>10 Completed</li>
                              <li>5 In Progress</li>
                              <li>11 Unassigned</li>
                            </ul>
                            <div className="progress-circle">
                              <span>46%</span>
                            </div>
                          </div>
                          <div className="progress-update">Updated: 02.11.23</div>
                        </Card.Body>
                      </Card>
                    </Col>

                    {/* Fourth Card */}
                    <Col md={6}>
                      <Card className="progress-card dimmed-card bg-dark text-white">
                        <Card.Body>
                          <Card.Title>Helmet</Card.Title>
                          <div className="progress-details">
                            <ul>
                              <li>10 Completed</li>
                              <li>5 In Progress</li>
                              <li>11 Unassigned</li>
                            </ul>
                            <div className="progress-circle">
                              <span>46%</span>
                            </div>
                          </div>
                          <div className="progress-update">Updated: 05.09.2023</div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  {/* Arrow Button */}
                  <div className="progress-arrow">
                    <img src="https://via.placeholder.com/30" alt="Arrow" />
                  </div>
                </div>
              </div>

            </Col>
          </Row>
        </Col>
  </Row>
</Container>

  );
}
